(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/sportkryss-wrapper/assets/javascripts/sportkryss-wrapper.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/sportkryss-wrapper/assets/javascripts/sportkryss-wrapper.js');
"use strict";


const {
  format
} = svs.utils;
const {
  SportkryssNumbers
} = svs.components.tipsen.SportkryssMybetsResult;
const {
  WinDiv
} = svs.components.tipsen.winDiv;
const SportkryssWrapper = _ref => {
  var _sportKryssDraw$drawR, _sportKryssWager$joke;
  let {
    displayResultTable,
    sportKryssDraw,
    sportKryssWager,
    branding
  } = _ref;
  const winDivArray = [];
  if (displayResultTable && sportKryssDraw !== null && sportKryssDraw !== void 0 && sportKryssDraw.distribution) {
    sportKryssDraw.distribution.map(item => winDivArray.push({
      winDiv: parseInt(item.name.split('')[0], 10),
      winners: item.winners,
      winValue: item.amount
    }));
  }
  return React.createElement("div", {
    className: "pg_sportkryss__bet_page sportkryss-wrapper"
  }, (sportKryssDraw === null || sportKryssDraw === void 0 || (_sportKryssDraw$drawR = sportKryssDraw.drawResult) === null || _sportKryssDraw$drawR === void 0 ? void 0 : _sportKryssDraw$drawR.numbers) && React.createElement(React.Fragment, null, React.createElement(SportkryssNumbers, {
    branding: branding,
    sportkryssetResultData: sportKryssDraw.drawResult.numbers,
    title: "R\xE4tt rad Sportkryss"
  }), winDivArray.length > 0 && React.createElement(React.Fragment, null, React.createElement(WinDiv, {
    className: "pg_windiv--result pg_windiv--result-sportkryss",
    drawWinDivs: winDivArray,
    isSportkryss: true
  }), React.createElement("div", {
    className: "sportkryss-turnover"
  }, React.createElement("span", null, "Oms\xE4ttning:"), React.createElement("span", {
    className: "turnover-value"
  }, "".concat(format.Currency(parseInt(sportKryssDraw.currentNetSale, 10)), " kr"))))), (sportKryssWager === null || sportKryssWager === void 0 || (_sportKryssWager$joke = sportKryssWager.jokerBoards[0]) === null || _sportKryssWager$joke === void 0 ? void 0 : _sportKryssWager$joke.boardData) && React.createElement(SportkryssNumbers, {
    branding: branding,
    sportkryssetBetData: sportKryssWager.jokerBoards[0].boardData,
    title: "Sportkryss (".concat(parseInt(sportKryssWager.betAmountSalesGroup1, 10), ") kr")
  }));
};
setGlobal('svs.components.marketplaceShared.sportkryssWrapper.SportkryssWrapper', SportkryssWrapper);

 })(window);